import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function getExecutionStatusMetrics() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbStats.executions.statusMetrics);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getAgentBuilderJobStatusMetrics() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appMbStats.agentBuilderJobs.statusMetrics
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
