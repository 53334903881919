import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Executions from 'components/mb-agents/agent-details/executions/Executions';
import Script from 'components/mb-agents/agent-details/Script';
import EnvVars from 'components/mb-agents/agent-details/EnvVars';
import Requirements from 'components/mb-agents/agent-details/Requirements';
import Panel from 'components/mb-agents/agent-details/panel/Panel';
import { getAgent } from 'helpers/requests/mb-agents';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const Agent = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [agent, setAgent] = useState({});
  const { id } = useParams();

  let handleGetAgent = async () => {
    const r = await getAgent(id);
    if (r.success) {
      setAgent(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  useEffect(() => {
    handleGetAgent();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      <Panel loading={loading} agent={agent} agentId={id} />

      {/* Rendering Executions only after we know for sure that the agent exists.
      Otherwise, 'Agent has no executions yet' message may flicker before Error404
      is shown. */}
      {!loading && (
        <>
          <Executions agentId={id} />
          <Script value={agent.script} />
          <Requirements value={agent.requirements} />
          <EnvVars value={agent.env_vars} />
        </>
      )}
    </>
  );
};

export default Agent;
