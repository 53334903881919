import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';
import is from 'is_js';
import MainLayout from 'layouts/MainLayout';

import ErrorLayout from 'layouts/ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/common/Error404';

import Login from 'components/authentication/login/Login';

import Profile from 'components/settings/profile/Profile';

import Agent from 'components/mb-agents/agent-details/Agent';

import Index from 'components/index/Index';
import AppContext from 'contexts/AppContext';
import { PrivateRoute, AuthRoute } from 'components/common/CustomRoutes';
import { appPaths } from 'config';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  // Must match frontend routes defined in core/config/settings.py on the backend
  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path={appPaths.errors.notFound} element={<Error404 />} />
        </Route>

        <Route element={<AuthRoute element={<AuthLayout />} />}>
          <Route path={appPaths.auth.login} element={<Login />} />
        </Route>

        <Route element={<PrivateRoute element={<MainLayout />} />}>
          <Route path={appPaths.index} element={<Index />} />
          <Route path={appPaths.settings.profile} element={<Profile />} />
          <Route path={appPaths.agents.detail} element={<Agent />} />
        </Route>

        <Route
          path="*"
          element={<Navigate to={appPaths.errors.notFound} replace />}
        />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
