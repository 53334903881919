import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import LogsModal from 'components/mb-agents/agent-details/logs/LogsModal';
import { getBuild } from 'helpers/requests/mb-agents';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const BuildStatus = ({ latestAgentBuilderJob: job }) => {
  const [loadingBuildLogs, setLoadingBuildLogs] = useState(false);
  const [showBuildLogsModal, setShowBuildLogsModal] = useState(false);
  const [buildLogs, setBuildLogs] = useState([]);

  const handleBuildLogsModalClose = () => {
    setShowBuildLogsModal(false);
  };

  const handleBuildLogsModalOpen = buildId => {
    handleGetBuildLogs(buildId);
    setShowBuildLogsModal(true);
  };

  let handleGetBuildLogs = async buildId => {
    setLoadingBuildLogs(true);
    const r = await getBuild(buildId);
    if (r.success) {
      setBuildLogs(r.response.data.logs);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoadingBuildLogs(false);
  };

  const getBadgeColor = status => {
    switch (status) {
      case 'success':
        return 'info';
      case 'failure':
        return 'danger';
      case 'in_progress':
        return 'warning';
      case null:
        return 'warning';
      default:
        return 'info';
    }
  };

  const getMessage = status => {
    switch (status) {
      case 'success':
        return 'Build succeeded';
      case 'failure':
        return 'Failed to build';
      case 'in_progress':
        return 'Build in progress';
      case null:
        return 'Build queued';
      default:
        return 'Unknown';
    }
  };

  const status = job.build ? job.build.status : null;
  return (
    <>
      <SoftBadge bg={getBadgeColor(status)}>{getMessage(status)}</SoftBadge>
      {status === 'failure' && (
        <Button
          variant="link"
          size="sm"
          onClick={() => handleBuildLogsModalOpen(job.build.id)}
        >
          <small>View build logs</small>
        </Button>
      )}
      <LogsModal
        logs={buildLogs}
        show={showBuildLogsModal}
        onHide={handleBuildLogsModalClose}
        loading={loadingBuildLogs}
      />
    </>
  );
};

export default BuildStatus;
